import React from "react";
import mainVid from "../Assests/VIdeos/Hero-vid.mp4"

const HeroSection = () => {
  return (
    <div className="relative h-screen overflow-hidden">
      <video className="absolute top-0 left-0 w-full h-full object-cover" autoPlay loop muted >
        <source src={mainVid} type="video/mp4" />
      </video>

        <div className="absolute text-left p-5 mt-36 md:mt-[500px] text-white">
          <p className="text-7xl md:text-7xl font-bold leading-tight">Delivering Clarity <br /> Beneath the Waves</p>          
        </div>
      </div>
  )
}
export default HeroSection