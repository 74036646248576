import React, { useState } from "react";
import Slider from "react-slick";
import { AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineArrowUp } from "react-icons/ai";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import bgImg1 from "../Assests/2. BATHYMETRY/Bathymetry 1.jpg";
import bgImg2 from "../Assests/4. ARCHEOLOGY/Archeo 2.jpg";
import bgImg3 from "../Assests/8. SURVEY BOATS/Survey Boat 2.jpg";
import bgImg4 from "../Assests/6. DEP RET/BUOY RET 1.jpg";
import bgImg5 from "../Assests/5. AUV GLIDER DEP/GOPR0190.JPG";
import bgImg6 from "../Assests/p8_20.png";
import bgImg7 from "../Assests/8. SURVEY BOATS/jetski.jpg";
import bgImg8 from "../Assests/3. ARTIFICIAL REEFS/Artificial reef 3.jpg";
import bgImg9 from "../Assests/p5_15.png";
import bgImg10 from "../Assests/6. DEP RET/BUOY DEP 3.jpg";
import bgImg11 from "../Assests/7. Dredging/Dredging 1.jpg";

const services = [
  {
    url: bgImg1,
    title: "Bathymetry Survey",
    shortDesc: "Single and multi-beam surveys to depths over 2000m.",
    longDesc:
      "Our Bathymetry Survey services are capable of carrying out single and multi-beam bathymetric surveys in water depths ranging from 1 meter to more than 2000 meters. Utilizing state-of-the-art equipment, we provide accurate and detailed underwater topographic mapping essential for various marine and offshore projects.",
    id: 1,
  },
  {
    url: bgImg2,
    title: "Oceanographic Measurements",
    shortDesc: "Current, wave, and tide measurements to 2000m.",
    longDesc:
      "We offer comprehensive oceanographic measurement services, including the use of conventional and acoustic current meters (ADCP) for measuring currents, waves, and tides. Our capabilities extend from very shallow waters to depths of 2000 meters. We also provide real-time data crucial for the oil & gas sector, and conduct sediment and water sample collection for Environmental Impact Assessments (EIA).",
    id: 2,
  },
  {
    url: bgImg3,
    title: "Offshore Work Boats",
    shortDesc: "Well-equipped boats for various offshore tasks.",
    longDesc:
      "Our fleet of boats is tailored for a wide range of offshore works. Each vessel is well-equipped with essential tools such as A-frames, winches, capstans, echo sounders, GPS, VHF communication systems, and safety equipment, ensuring efficiency and safety in all marine-related services.",
    id: 3,
  },
  {
    url: bgImg4,
    title: "Buoy Deployment & Retrieval",
    shortDesc: "Expert handling of oceanographic equipment.",
    longDesc:
      "Our team specializes in the deployment and retrieval of various oceanographic equipment and systems, including data buoys, current meters, Acoustic Doppler Current Profilers (ADCP), and subsurface systems for ambient noise measurement. We ensure precision and reliability in handling these critical instruments.",
    id: 4,
  },
  {
    url: bgImg5,
    title: "Diving Assistance",
    shortDesc: "Open sea underwater diving for technical works.",
    longDesc:
      "We provide professional underwater diving assistance for a variety of marine technical works. Our divers are equipped with the necessary tools and expertise to conduct operations in challenging open sea environments, ensuring the success of your projects.",
    id: 5,
  },
  {
    url: bgImg6,
    title: "EIA Sample Collection",
    shortDesc: "Sediment and water samples for environmental studies.",
    longDesc:
      "Our team is highly experienced in collecting sediment and water samples for Environmental Impact Assessments (EIA). We adhere to stringent protocols to ensure the accuracy and integrity of samples, providing reliable data for environmental studies and compliance.",
    id: 6,
  },
  {
    url: bgImg7,
    title: "Towing & Patrolling Boats",
    shortDesc: "Boats for towing, patrolling, and pilot launches.",
    longDesc:
      "We supply boats designed for towing, patrolling, and pilot launch services in harbors. Our vessels are equipped to handle a range of maritime tasks, providing essential support for harbor operations and coastal security.",
    id: 7,
  },
  {
    url: bgImg8,
    title: "Artificial Reef Deployment",
    shortDesc: "Creating reefs to enhance marine habitats.",
    longDesc:
      "We are experts in the preparation and deployment of artificial reefs in shallow waters. These structures are designed to enhance marine habitats, promoting biodiversity and supporting sustainable fisheries. Our team ensures precise placement and stability of these artificial reefs.",
    id: 8,
  },
  {
    url: bgImg9,
    title: "Coastal Erosion Protection",
    shortDesc: "Geo bag dyke installation to prevent erosion.",
    longDesc:
      "Our services include the installation of Geo bag dykes along coastal lines to combat erosion. This method is effective in protecting shorelines and preventing land loss. We provide tailored solutions to address specific erosion issues, ensuring long-term coastal stability.",
    id: 9,
  },
  {
    url: bgImg10,
    title: "Solar Marker Floats",
    shortDesc: "Solar-powered floats for navigation.",
    longDesc:
      "We supply solar-powered marker floats designed for navigational purposes. These eco-friendly devices provide reliable and sustainable marking for maritime routes, ensuring safe navigation in various marine environments.",
    id: 10,
  },
  {
    url: bgImg11,
    title: "Shallow Water Dredging",
    shortDesc: "Dredging and core sample collection.",
    longDesc:
      "Our shallow water dredging services include core sample collection for geological and environmental studies. We use specialized equipment to ensure precise and efficient dredging operations, providing valuable data for project planning and assessment.",
    id: 11,
  },
];
const SamplePrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow service-prev ${className}`}>
      <AiOutlineArrowLeft className="arrows" style={{ color: "white" }} />
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow service-next ${className}`}>
      <AiOutlineArrowRight className="arrows" style={{ color: "white" }} />
    </div>
  );
};

const ServiceCards = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleLearnMore = (service) => {
    setModalContent(service);
    setModalVisible(true);
  };

  var servicesSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const Modal = ({ show, onClose, content }) => {
    if (!show) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
        <div className="bg-blue-200 p-2 rounded-lg w-[700px] h-[700px] flex flex-col justify-center items-center">
          <h2 className="text-2xl font-bold mb-4 text-black">{content.title}</h2>
          <img src={content.url} alt={content.title} className="w-[500px] h-[450px] mb-2 rounded-lg object-cover" />
          <p className="text-center p-2 text-black">{content.longDesc}</p>
          <button onClick={onClose} className="bg-white text-blue-500 px-4 py-2 mt-1 rounded-lg">Close</button>
        </div>
      </div>
    );
  };

  return (
    <div id="services" className="flex flex-col justify-center px-4 gap-2 mt-4 h-screen">
      <h1 className="text-3xl text-black ml-8 font-bold">Services we Offer</h1>
      <p className="text-lg ml-8">Specialized Marine Services for Every Challenge</p>
      <div className="w-full p-4">
        <Slider {...servicesSettings}>
          {services.map((item, index) => (
            <div key={index} className="p-2">
              <div className="bg-white h-[480px] rounded-xl flex flex-col justify-between items-center border-b-[8px] border-[#01365e] shadow-md">
                <div className="w-full">
                  {item.url && <img src={item.url} alt={item.title} className="h-[280px] w-full rounded-t-xl" />}
                </div>
                <div className="flex flex-col justify-between items-center h-[200px]">
                  <h1 className="mt-2 text-black text-center text-xl font-bold">{item.title}</h1>
                  <p className="text-center truncate-lines">{item.shortDesc}</p>
                  <button
                    onClick={() => handleLearnMore(item)}
                    className="rounded-xl relative mb-2 w-36 h-9 cursor-pointer flex items-center bg-blue-400 group hover:bg-blue-800"
                  >
                    <span className="text-white ml-3 transform group-hover:hidden transition-all duration-300">
                      Learn More
                    </span>
                    <span className="absolute right-0 h-full w-10 rounded-xl bg-blue-800 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                      <AiOutlineArrowUp className="text-white transform transition-transform duration-300 ease-in-out rotate-45" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <Modal show={modalVisible} onClose={() => setModalVisible(false)} content={modalContent} />
    </div>
  );
};

export default ServiceCards;