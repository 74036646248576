import React, { useState } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import { AiOutlineArrowRight, AiOutlineArrowLeft, AiOutlineArrowUp } from "react-icons/ai";
import cruise from "../Assests/Projects/cruise.gif";
import rudder from "../Assests/Projects/rudder.gif";
import lighthouse from "../Assests/Projects/lighthouse.gif";
import buoy from "../Assests/Projects/buoy.gif";
import oyster from "../Assests/Projects/oyster.gif";
import snorkel from "../Assests/Projects/snorkel.gif";
import rowing from "../Assests/Projects/rowing.gif";
import coral from "../Assests/Projects/coral.gif";
import ocean from "../Assests/Projects/flood.gif";
import anchor from "../Assests/Projects/anchor.gif";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const projects = [
  {
    title: "Bathymetric Survey along South East India",
    description: "Conducted near-shore bathymetric survey (0 to 5m) using Jetski along the South East coast of India.",
    img: coral,
  },
  {
    title: "National Bathymetry Survey Support",
    description: "Supplied survey vessels for the National Bathymetry survey along the coastlines of Andhra Pradesh, Tamil Nadu, and Pondicherry for the National Institute of Ocean Technology (NIOT), Chennai.",
    img: rudder,
  },
  {
    title: "Archeological Survey for IMU",
    description: "Executed archeological surveys for the Indian Maritime University (IMU) in Visakhapatnam.",
    img: snorkel,
  },
  {
    title: "Marine Instrumentation Deployment and Retrieval",
    description: "Provided skilled and semi-skilled manpower for the deployment and retrieval of tide gauges, current meters, sound velocity profilers, Tsunami buoys, and Water Quality buoys for the National Centre for Coastal Research (NCCR) and NIOT, Chennai.",
    img: rowing,
  },
  {
    title: "Bathymetry Survey and Dredging Support for Adani Ports",
    description: "Performed bathymetry surveys and provided dredging support assistance for Adani Ports at Kattupalli, Chennai.",
    img: ocean,
  },
  {
    title: "Artificial Reef Creation for Tamil Nadu Fisheries",
    description: "Deployed concrete structures to create artificial reefs for the Department of Fisheries, Government of Tamil Nadu.",
    img: lighthouse,
  },
  {
    title: "Periodic Maintenance of Moored Buoy Pipeline",
    description: "Conducted periodic maintenance of moored buoy pipelines off the coast of Chennai for Manali Petrochemicals Limited (MPL), Chennai.",
    img: cruise,
  },
  {
    title: "Buoy Deployment and Offshore Manpower Supply",
    description: "Provided buoy deployment and retrieval services and offshore manpower supply for NORINCO, Chennai.",
    img: buoy,
  },
  {
    title: "Dredging Quantity Analysis for IFCD",
    description: "Conducted dredging quantity analysis for the Department of Irrigation and Flood Control (IFCD), Government of National Capital Territory of Delhi, New Delhi.",
    img: anchor,
  },
  {
    title: "Bathymetry Survey for KMML",
    description: "Executed bathymetry surveys along the coast of Kollam for Kerala Minerals and Mines Limited (KMML), a Government of Kerala undertaking.",
    img: oyster,
  },
];

const ProjectsSlider = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent({});
  };

  const settings = {
    dots:false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    className: "slides",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="max-w-[1300px] mx-auto mt-16" id="projects">
      <h2 className="text-3xl font-bold mb-6">Showcasing Our Expertise: Completed Projects</h2>
      <Slider {...settings}>
        {projects.map((project, index) => (
          <div key={index} className="px-20 md:p-6 md:-mx-3">
            <div className="bg-white border h-[360px] w-[240px] md:w-[300px] text-center rounded-xl shadow-lg">
              <div className="flex flex-col justify-between items-center p-3 h-full">
                {project.img && <img src={project.img} alt={project.title} className="h-20" />}
                <p className="text-lg font-semibold text-blue-600">{project.title}</p>
                <p className="text-md truncate-lines">{project.description}</p>
                <button
                  onClick={() => openModal(project)}
                  className="rounded-xl relative mt-2 w-36 h-9 cursor-pointer flex items-center bg-blue-400 group hover:bg-blue-800"
                >
                  <span className="text-white ml-3 transform group-hover:hidden transition-all duration-300">Learn More</span>
                  <span className="absolute right-0 h-full w-10 rounded-xl bg-blue-800 flex items-center justify-center transform group-hover:translate-x-0 group-hover:w-full transition-all duration-300">
                    <AiOutlineArrowUp className="text-white transform transition-transform duration-300 ease-in-out rotate-45" />
                  </span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Project Description"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div className="bg-white p-6 rounded-md shadow-md">
          <h2 className="text-2xl font-bold mb-4">{modalContent.title}</h2>
          <p className="text-sm text-gray-600 mb-4">{modalContent.description}</p>
          <button
            className="mt-3 p-2 border bg-blue-500 rounded-md text-white text-sm"
            onClick={closeModal}
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};



const SamplePrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow project-prev ${className}`}>
      <AiOutlineArrowLeft className="project-arrows" style={{ color: "white" }} />
    </div>
  );
};

const SampleNextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={`arrow project-next ${className}`}>
      <AiOutlineArrowRight className="project-arrows" style={{ color: "white" }} />
    </div>
  );
};
export default ProjectsSlider;
