import React, { useEffect, useRef, useState } from "react";
import logo1 from '../Assests/Client-Logo/NCCR.png';
import logo2 from '../Assests/Client-Logo/Dredging_Corp_of_India_logo.png';
import logo3 from '../Assests/Client-Logo/Geological_Survey_of_India_Logo.png';
import logo4 from '../Assests/Client-Logo/Indian_Maritime_University_logo.png';
import logo5 from '../Assests/Client-Logo/adani.png';
import logo6 from '../Assests/Client-Logo/fugro.png';
import logo7 from '../Assests/Client-Logo/ifcd.png';
import logo8 from '../Assests/Client-Logo/iitm.png';
import logo9 from '../Assests/Client-Logo/images.png';
import logo10 from '../Assests/Client-Logo/norinco.png';
import logo11 from '../Assests/Client-Logo/kscadc.png';

const customers = [
    { src: logo1, alt: 'Customer 1' },
    { src: logo2, alt: 'Customer 2' },
    { src: logo3, alt: 'Customer 3' },
    { src: logo4, alt: 'Customer 4' },
    { src: logo5, alt: 'Customer 5' },
    { src: logo6, alt: 'Customer 6' },
    { src: logo7, alt: 'Customer 7' },
    { src: logo8, alt: 'Customer 8' },
    { src: logo9, alt: 'Customer 9' },
    { src: logo10, alt: 'Customer 10' },
    { src: logo11, alt: 'Customer 11' },
];

const Customers = () => {
  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={sectionRef} id="customers" className="max-w-[1300px] mx-auto mt-2">
      <h1 className="text-3xl font-bold mb-6">
        Partnering with the <br />Pioneers of the Industry
      </h1>
      <div>
      
      <div className=" bg-white shadow-lg rounded-xl p-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-12 p-6">
        {customers.map((customer, index) => (
          <div key={index} className="flex items-center justify-center">
            <img 
              src={customer.src} 
              alt={customer.alt} 
              className={`max-h-20 grayscale ${isVisible ? 'show-color' : ''}`}
            />
          </div>
        ))}
      </div>
    </div>
    </div>
  );
};

export default Customers;
