import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import infra1 from '../Assests/Infra/PHOTO-2024-07-01-10-58-43.jpg';
import infra2 from '../Assests/Infra/PHOTO-2024-07-01-10-59-06.jpg';
import infra3 from '../Assests/Infra/PHOTO-2024-07-01-10-59-20.jpg';
import infra4 from '../Assests/Infra/PHOTO-2024-07-01-10-59-33.jpg';
import infra5 from '../Assests/Infra/PHOTO-2024-07-01-11-00-14.jpg';
import infra6 from '../Assests/Infra/PHOTO-2024-07-01-11-04-19.jpg';
import infra7 from '../Assests/Infra/PHOTO-2024-07-01-11-20-59.jpg';
import infra8 from '../Assests/Infra/PHOTO-2024-07-01-11-40-06.jpg';
import infra9 from '../Assests/Infra/PHOTO-2024-07-01-13-11-21.jpg';
import infra10 from '../Assests/Infra/PHOTO-2024-07-01-13-14-15.jpg';
import infra11 from '../Assests/Infra/PHOTO-2024-07-01-17-40-38.jpg';
import infra12 from '../Assests/Infra/PHOTO-2024-07-01-17-38-03.jpg';
import infra13 from '../Assests/Infra/PHOTO-2024-07-01-17-40-15.jpg';
import infra14 from '../Assests/Infra/PHOTO-2024-07-01-17-42-00.jpg';
import infra15 from '../Assests/Infra/PHOTO-2024-07-01-17-45-16.jpg';
import infra16 from '../Assests/Infra/PHOTO-2024-07-01-17-52-08.jpg';
import infra17 from '../Assests/Infra/PHOTO-2024-07-01-17-58-35.jpg';
import infra18 from '../Assests/Infra/WhatsApp Image 2024-07-02 at 09.47.29.jpeg';

const Infra = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3500,
    autoplaySpeed: 3500,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const reverseSettings = {
    ...settings,
    rtl: true,
  };

  const infraData = [
    { src: infra1, description: "Dynamic Wave Recorder(DWR) Deployment"},
    { src: infra2, description: "On-board Survey"},
    { src: infra3, description: "Prepartion of field materials"},
    { src: infra4, description: "Mounting"},
    { src: infra5, description: "Echosounder and DGPS on Survey Vessel"},
    { src: infra6, description: "Buoy Loading"},
    { src: infra7, description: "Side Scan Sonar"},
    { src: infra8, description: "Remotely Operated Vehicle (ROV)"},
    { src: infra9, description: "Sediment Sampler"},
    { src: infra10, description: "Water Sampler"},
    { src: infra11, description: "Hemisphere DGNSS"},
    { src: infra12, description: "CeeScope"},
    { src: infra13, description: "Dual Frequency Transducer"},
    { src: infra14, description: "Motion Reference Unit"},
    { src: infra15, description: "Teledyne Echotrak E20"},
    { src: infra16, description: "VHF"},
    { src: infra17, description: "Interfaces"},
    { src: infra18, description: "Cameras and Underwater Cases"}
  ];

  const firstHalfData = infraData.slice(0, 9);
  const secondHalfData = infraData.slice(9);

  return (
    <div className="py-10 mt-6" id="infrastructure">
      <div className="">
        <h1 className="text-black w-max-[1300px] mx-auto text-3xl font-bold mb-6 ml-16">Infrastructure</h1>
        <Slider {...reverseSettings}>
          {firstHalfData.map((item, index) => (
            <div key={index} className="text-center p-2">
              <div className="bg-white border border-blue-200 rounded-xl">
                <img src={item.src} className="h-40 w-64 overflow-hidden rounded-t-lg mx-auto" alt={item.description} />
                <p className="text-black p-2">{item.description}</p>
              </div>
            </div>
          ))}
        </Slider>
        <Slider {...settings} className="mt-2">
          {secondHalfData.map((item, index) => (
            <div key={index} className="text-center p-2">
              <div className="bg-white border border-blue-200 rounded-xl">
                <img src={item.src} className="h-40 w-64 overflow-hidden rounded-t-lg mx-auto" alt={item.description} />
                <p className="text-black p-2">{item.description}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Infra;
