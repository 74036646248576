import React from "react";
import bgVid from "../Assests/VIdeos/Service-vid.mp4";
import { MdLocationOn, MdEmail, MdPhoneInTalk} from "react-icons/md";

const ContactFooter = () => {
  return (
    <div id="contact" className="relative min-h-screen overflow-hidden mt-4">
    <video className="absolute top-0 left-0 w-full h-full object-cover" autoPlay loop muted>
      <source src={bgVid} type="video/mp4" />
    </video>
    <div className='relative font-extrabold text-center text-white text-4xl mt-28'>
      <h1>Contact Us</h1>
    </div>
    <div class="mt-10">
    <div class="grid gap-x-10 p-10 gap-8 md:p-24 grid-cols-1 md:grid-cols-3">
      
      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white bg-opacity-50 text-gray-700 shadow-md hover:scale-105">
        <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-blue-600 to-blue-400 text-white shadow-blue-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
        <MdLocationOn className='h-8 w-10'/>
        </div>
        <div class="p-2 md:p-4">
          <h4 class="text-[20px] font-semibold text-blue-gray-900 flex flex-col justify-center items-center md:mt-8">
            No.264, 3rd Main Road, <br /> 8th Cross Street, Pattinapakkam,<br />Chennai - 600 119</h4>
        </div>
      </div>

      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white bg-opacity-50 text-gray-700 shadow-md hover:scale-105">
        <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-pink-600 to-pink-400 text-white shadow-pink-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
        <MdEmail className='h-8 w-10'/>
        </div>
        <div class="p-4 md:p-4 flex flex-col justify-center items-center">
          <div class="text-[20px] font-semibold text-blue-gray-900 mt-10">
            <p className="mt-2">cosmicpvtltd2018@gmail.com</p>
          </div>
        </div>  
      </div>

      <div class="relative flex flex-col bg-clip-border rounded-xl bg-white bg-opacity-50 text-gray-700 shadow-md hover:scale-105">
        <div class="bg-clip-border mx-4 rounded-xl overflow-hidden bg-gradient-to-tr from-green-600 to-green-400 text-white shadow-green-500/40 shadow-lg absolute -mt-4 grid h-16 w-16 place-items-center">
        <MdPhoneInTalk className='h-8 w-10'/>
        </div>
        <div class="p-4 md:p-4 flex flex-col justify-center items-center text-[20px] font-semibold text-blue-gray-900">
          <p className="  md:mt-8">+91 95661 74528</p>
          <p>+91 90474 84424</p>
        </div>
      </div>
    </div>
    <footer className="relative -mt-2">
    <div class="relative max-w-screen-xl px-4 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <nav class="flex flex-wrap justify-center -mx-5 -my-2">
            <div class="px-5 mt-2 ">
                <a href="#" class="font-bold leading-6 text-white hover:text-gray-900">
                    Home
                </a>
            </div>
            <div class="px-5 mt-2 ">
                <a href="#services" class="font-bold leading-6 text-white hover:text-gray-900">
                    Services
                </a>
            </div>
            <div class="px-5 mt-2 ">
                <a href="#projects" class="font-bold leading-6 text-white hover:text-gray-900">
                    Projects
                </a>
            </div>
            <div class="px-5 mt-2 ">
                <a href="#contact" class="font-bold leading-6 text-white hover:text-gray-900">
                    Contact
                </a>
            </div>
            <div class="px-5 mt-2 ">
                <a href="#" class="font-bold leading-6 text-white hover:text-gray-900">
                    Privacy
                </a>
            </div>
            <div class="px-5 mt-2">
                <a href="#" class="font-bold leading-6 text-white hover:text-gray-900">
                    Terms
                </a>
            </div>
        </nav>
        <div class="relative flex justify-center space-x-6">
            <a href="#" class="text-white hover:text-gray-900">
                <span class="sr-only">Facebook</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd"></path>
                </svg>
            </a>
            <a href="#" class="text-white hover:text-gray-900">
                <span class="sr-only">Instagram</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                    <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd"></path>
                </svg>
            </a>
            <a href="#" class="text-white hover:text-gray-900">
                <span class="sr-only">WhatsApp</span>
                <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"></path>
                </svg>
            </a>
           
        </div>
        <p className="relative text-base text-center text-white mb-1">&copy; 2023 COSMIC - Coromandel Ocean Service. Designed by <a href="https://namuvi.com/" className='underline text-[#96d3ee] font-semibold'>Namuvi Technologies Pvt.Ltd</a></p>
    </div>
  </footer>
    </div>
   
  </div>
  )
}
export default ContactFooter