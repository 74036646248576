import React, { useEffect, useRef, useState } from "react";
import { MdAnchor } from "react-icons/md";
import { VerticalTimeline, VerticalTimelineElement } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { motion, useAnimation } from "framer-motion";


const About = () => {
  const [isVisible, setIsVisible] = useState(false);
  const controls = useAnimation();
  const timelineRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (timelineRef.current) {
        const top = timelineRef.current.getBoundingClientRect().top;
        const isVisible = top < window.innerHeight * 0.8; 
        setIsVisible(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isVisible) {
      controls.start({
        height: "100%",
        opacity: 1,
        transition: { duration: 1, ease: "easeInOut" },
      });
    } else {
      controls.start({
        height: "0%",
        opacity: 0,
        transition: { duration: 0.5, ease: "easeInOut" },
      });
    }
  }, [isVisible, controls]);

  return (
    <div id="about" className="max-w-[1300px] mx-auto mt-10 rounded-xl relative">
      <div className="relative z-10 about-bg rounded-xl">
        <div className="vertical-timeline-container">
        <VerticalTimeline lineColor="#fff" className="z-10">
  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: 'transparent', color: '#fff' }}
    iconStyle={{ background: "#000", color: '#fff' }}
    icon={<MdAnchor />}
  >
    <h1 className="text-2xl font-bold">Who We Are</h1>
    <p className="text-lg md:text-xl">Coromandel Ocean Service is a proprietor company registered under MSME. It is one of the emerging coastal marine service providers to Ocean Technologists, Dredging Companies, Harbour and Port, Offshore Companies, and Various Universities.</p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: 'transparent', color: '#fff' }}
    iconStyle={{ background: '#000', color: '#fff' }}
    icon={<MdAnchor />}
  >
    <h1 className="text-2xl font-bold">Vision</h1>
    <p className="text-xl md:text-lg">To be the premier provider of marine services, advancing technology and innovation in coastal and offshore industries.</p>
    <h1 className="text-2xl font-bold mt-2">Mission</h1>
    <p className="text-xl md:text-lg">To deliver high-quality marine services that exceed our clients' expectations, fostering growth and development in the marine industry.</p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element"
    contentStyle={{ background: 'transparent', color: '#fff' }}
    iconStyle={{ background: '#000', color: '#fff' }}
    icon={<MdAnchor />}
  >
    <h1 className="text-2xl font-bold">Goals</h1>
    <ul className="list-disc list-inside">
      <li>Expand our service offerings to new regions and markets.</li>
      <li>Invest in cutting-edge technology and equipment.</li>
      <li>Build long-term relationships with clients and partners.</li>
      <li>Commit to sustainable and environmentally friendly practices.</li>
    </ul>
  </VerticalTimelineElement>
</VerticalTimeline>


          <motion.div
            className="vertical-timeline"
            initial={{ height: "0%", opacity: 0 }}
            animate={controls}
          />
        </div>
      </div>
    </div>
  );
}

export default About;
