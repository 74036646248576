import React from "react";
import Header from "./Components/Header";
import HeroSection from "./Components/HeroSection";
import ContactFooter from "./Components/ContactFooter";
import Customers from "./Components/Customers";
import About from "./Components/About";
import ServiceCards from "./Components/ServiceCards";
import Projects from "./Components/Projects";
import Infra from "./Components/Infra";

function App() {
  return (
    <div>
      <Header/>
      <HeroSection/>
      <About/>
      <ServiceCards/>
      <Customers/>
      <Projects/>
      <Infra/>
      <ContactFooter />
    </div>
  );
}

export default App;
